import {Carousel} from 'antd';
import Im1 from '../assets/im1.png';
import Im2 from '../assets/im2.png';
import Im3 from '../assets/im3.png';
import Container from "./Container";

const images = [
    {
        url: Im1,
    },
    {
        url: Im2,
    },
    {
        url: Im3,
    },
];

export default function Works() {
    return <div className="works">
        <h2>نمونه کار ها</h2>
        <Container className="slide-container" dir="ltr">
            <Carousel autoplay draggable={true} arrows={true}>
                {images.map((value) => (
                    <img key={value.url} src={value.url}/>
                ))}
            </Carousel>
        </Container>
    </div>
}
