export default function Contact() {
    return (
        <div id="contact">
            <h1>
                در دست ساخت
            </h1>
        </div>

    )
}
