import hairPlant from '../assets/hair-plant2.png'
import arrow from '../assets/arrow-black.png'

export default function DestTabSecond() {
    return <div className="dest-tab-second">
        <img className="dest-tab-img" src={hairPlant}/>
        <img className="arrow-black" src={arrow}/>

        <div className="dest-tab-text">
            <h2>چرا ما؟</h2>
            <ul>
                <li>استفاده از تیغ پانچ میلیمتری</li>
                <li>تراکم حداکثر در قسمت هیرلاین</li>
            </ul>
        </div>
    </div>
}
