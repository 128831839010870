import logo from './logo.svg';
import './App.css';
import Header from "./Components/Header";
import MainBanner from "./Components/MainBanner";
import WhatsappChat from "./Components/WhatsappChat";
import DestTab from "./Components/DestTab";
import DestTabSecond from "./Components/DestTabSecond";
import Works from "./Components/Works";
import Questions from "./Components/Questions";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";

function App() {
    return (
        <div className="App">
            <Header/>
            <MainBanner/>
            <WhatsappChat/>
            <DestTabSecond/>
            <DestTab/>
            <Works/>
            <Questions/>
            <Contact/>
            <Footer/>
        </div>
    );
}

export default App;
