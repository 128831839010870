export default function MainBanner(){
    return <div className="banner">
        <p className="banner-text">
            برای مشاوره
            <br/>
            آنلاین
            <br/>
            <a href="#contact" className="banner-btn">کلیک کنید</a>
        </p>
    </div>
}
