import {Collapse} from "antd";
import question from '../assets/question.svg'

export default function Questions() {
    const text = "lorem ipsum..."
    const items = [
        {
            key: '1',
            label: 'This is panel header 1',
            children: <p>{text}</p>,
            style: {background: 'white', border: '3px solid #002766', borderRadius: '1rem 1rem 0 0'},

        },
        {
            key: '2',
            label: 'This is panel header 2',
            children: <p>{text}</p>,
            style: {background: 'white', border: '3px solid #002766'},

        },
        {
            key: '3',
            label: 'This is panel header 3',
            children: <p>{text}</p>,
            style: {background: 'white', border: '3px solid #002766', borderRadius: '0 0 1rem 1rem'},

        },
    ];
    return (
        <div className="questions">
            <h2>سوالات متداول</h2>
            <div className="question-box">
                <img src={question}/>
                <Collapse className="question-collapse" items={items} defaultActiveKey={['1']}/>
                <img src={question} style={{transform: 'scaleX(-1)'}}/>
            </div>
        </div>
    )
}
