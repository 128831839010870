import hairPlant from '../assets/hair-plant.png'
import arrow from '../assets/arrow.png'

export default function DestTab() {
    return <div className="dest-tab">
        <img className="dest-tab-img" src={hairPlant}/>
        <img className="arrow" src={arrow}/>
        <div className="dest-tab-text">
            <h2>چرا کاشت مو؟</h2>
            <p>
                کاشت مو روشی مدرن برای حل مشکلات ریزش و کمبود مو است. این روش باعث افزایش اعتماد به نفس، ظاهر جوان‌تر و
                طبیعی‌تر می‌شود. همچنین، کاشت مو کمترین عوارض جانبی را دارد و موهای کاشته‌شده ماندگاری بالایی دارند. این
                روش نسبت به دیگر روش‌های جبران مو، هزینه کمتری دارد. به طور کلی، کاشت مو روشی موثر، امن و اقتصادی است که
                کیفیت زندگی افراد را بهبود می‌بخشد.
            </p>
        </div>
    </div>
}
